import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { SaveGuardComponent } from '../../../shared/components/save-guard/save-guard.component';
import { MainNavigationService } from '../../../shared/services/main-navigation.service';
import { User } from '../../../shared/classes/user';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { UserApiService } from '../../../shared/services/APIServices/user-api.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  public editMode: boolean = false;
  private saveGuardDialogRef: MatDialogRef<SaveGuardComponent>;
  public user: User;

  public passwordNew: string;
  public passwordNewSecond: string;

  public profileEdit: boolean = false;
  private restoreUser: User;


  constructor(private dialog: MatDialog, public mainNavService: MainNavigationService, private authService: AuthenticationService, private usersService: UserApiService) { }

  ngOnInit() {
    this.authService.jwt_timeout();
    this.usersService.getUserWithID(this.authService.user.user_id).subscribe(
      user => {
        this.user = user;
      },
      error => {
        console.error(error);
      }
    )

  }

  saveChanges() {
    if(this.passwordNew != undefined && this.passwordNewSecond != undefined) {
      if(this.passwordNew == this.passwordNewSecond){
        this.user['password'] = this.passwordNew;
      }
      else {
      }
    }
    this.usersService.updateUserWithID(this.authService.user.user_id, this.user).subscribe(
      user => {
        var company;
        if (this.user.company != undefined) {
          company = this.user.company;
        }
        this.user = user;
        this.restoreUser = null;
        this.endEditUser();
      },
      error => {
        console.error(error);
      }
    )
  }

  canDeactivate() {
    if (this.editMode) {
      this.saveGuardDialogRef = this.dialog.open(SaveGuardComponent,
      {
        panelClass: 'dialogMobileFullWidth'
      });
      return this.saveGuardDialogRef.afterClosed().subscribe(result => {
        if (result == 'confirm' && result != undefined) {
          // this.endEdit();
          return true;
        }
        else if (result == 'saveChanges' && result !== undefined) {
          this.saveChanges();
          return true;
        }
      });
    }
    else {
      return true;
    }
  }


  startEditUser() {
    this.restoreUser = User.copyObject(this.user);
    this.profileEdit = true;
  }

  endEditUser() {
    this.profileEdit = false;
  }

  cancelEditUser() {
    this.user = User.copyObject(this.restoreUser);
    this.restoreUser = null;
    this.endEditUser();
  }

}
